import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.object.assign";
import e from "react";

var n = e.createContext({}),
    r = function r(n) {
  return function (r) {
    var a = t(r.components);
    return e.createElement(n, Object.assign({}, r, {
      components: a
    }));
  };
},
    t = function t(r) {
  var t = e.useContext(n),
      a = t;
  return r && (a = "function" == typeof r ? r(t) : Object.assign({}, t, r)), a;
},
    a = function a(r) {
  var a = t(r.components);
  return e.createElement(n.Provider, {
    value: a
  }, r.children);
},
    o = "mdxType",
    c = {
  inlineCode: "code",
  wrapper: function wrapper(n) {
    return e.createElement(e.Fragment, {}, n.children);
  }
},
    i = function i(n) {
  var r = n.components,
      a = n.mdxType,
      o = n.originalType,
      i = n.parentName,
      p = function (e, n) {
    var r = {};

    for (var t in e) {
      Object.prototype.hasOwnProperty.call(e, t) && -1 === n.indexOf(t) && (r[t] = e[t]);
    }

    return r;
  }(n, ["components", "mdxType", "originalType", "parentName"]),
      l = t(r);

  return e.createElement(l[i + "." + a] || l[a] || c[a] || o, r ? Object.assign({}, p, {
    components: r
  }) : p);
};

function p(n, r) {
  var t = arguments,
      a = r && r.mdxType;

  if ("string" == typeof n || a) {
    var c = t.length,
        p = new Array(c);
    p[0] = i;
    var l = {};

    for (var m in r) {
      hasOwnProperty.call(r, m) && (l[m] = r[m]);
    }

    l.originalType = n, l[o] = "string" == typeof n ? n : a, p[1] = l;

    for (var u = 2; u < c; u++) {
      p[u] = t[u];
    }

    return e.createElement.apply(null, p);
  }

  return e.createElement.apply(null, t);
}

i.displayName = "MDXCreateElement";
export { n as MDXContext, a as MDXProvider, t as useMDXComponents, r as withMDXComponents, p as mdx };